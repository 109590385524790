import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
// import { useLocation } from 'react-router-dom';
import { _isUserLoggedIn } from 'utils';
import errorHandler from 'handlers/errorHandler';
import { socialLoginTelegram } from 'api/apiRequest';
// import { hammer_logo_2 } from 'assets';
import Image1 from 'assets/onboarding/hammerLoadingAd.png';
// import Image2 from 'assets/onboarding/loading2.png';
// import Image3 from 'assets/onboarding/loading3.png';
import AppLoader from 'components/gifs/App-loader.gif';
import * as config from 'config';
import { setLoginSuccessFunction } from 'utils/loginSuccess';

interface Props {
	onLoginSuccess: () => void;
}

export function LoadingPage({ onLoginSuccess }: Props) {
	const navigate = useNavigate();
	const loggedIn = _isUserLoggedIn();

	const [errorMsg, setErrorMsg] = useState<any>();
	let initData: any;
	let initDataUnsafe: any;
	const domain = process.env.REACT_APP_HAMMER_GAMES_DOMAIN || 'defaultDomain';

	const env = process.env.NODE_ENV;

	if (env == 'development') {
		initData = config?.initData;
		initDataUnsafe = config?.initDataUnsafe;
	} else {
		// Non-local environment (e.g., staging)yarn
		initDataUnsafe = window?.Telegram?.WebApp?.initDataUnsafe || {};
		initData = window?.Telegram?.WebApp?.initData || {};
	}

	// Store the function in the singleton
	setLoginSuccessFunction(onLoginSuccess);

	useEffect(() => {
		// Preload images and GIFs
		const imagesToPreload = [Image1, AppLoader];
		imagesToPreload.forEach((src: any) => {
			const img = new Image();
			img.src = src;
		});
	}, []);

	const { mutate: telegramMutate, isLoading } = useMutation(
		socialLoginTelegram,
		{
			onError: (error: any) => {
				errorHandler(error, true);
				setErrorMsg(error);
			},
			onSuccess: (res: any) => {
				if (
					res?.data?.accessSecret === null ||
					res?.action === 'country_not_in_profile'
				) {
					onLoginSuccess();
					navigate('/auth/complete-registration', {
						state: {
							deviceCountry: res?.data?.device?.cfIpCountry,
							accessToken: res?.data?.accessToken,
							// referrer: query,
						},
					});
				} else {
					localStorage.setItem('game-user-token', res?.data?.accessToken);
					localStorage.setItem('game-user-secret', res?.data?.accessSecret);
					if (res?.data?.user) {
						localStorage.setItem(
							'game-user-obj',
							JSON.stringify(res?.data?.user)
						);
						localStorage.setItem('isFirstTimeUser', 'false');
					}
					onLoginSuccess();
				}
			},
			retry: 0,
		}
	);

	useEffect(() => {
		if (
			Object.keys(initDataUnsafe).length > 0 &&
			Object.keys(initData).length > 0 &&
			!loggedIn
		) {
			telegramMutate({
				data: {
					initData: initData,
					initDataUnsafe: initDataUnsafe,
				},
				domain,
			});
		} else {
			onLoginSuccess();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className="relative w-full h-screen overflow-hidden loading-page-bg">
			<img src={Image1} alt="Image" className="absolute" />

			{errorMsg && (
				<div className="py-8 text-white">{JSON.stringify(errorMsg)}</div>
			)}
			{isLoading && (
				<div className="justify-center items-center-flex">
					<img src={AppLoader} className="max-h-[200px]" alt="gif" />
				</div>
			)}
		</div>
	);
}
